import React from "react";
import { splitText } from "../../helpers/helpers";
import styles from "./logo.module.scss";

const Loader = () => {
  return (
    <h1 className={styles.logo}>
      <span className={styles.text}>{splitText("JASON")}</span>
      <span className={styles.text}>{splitText("HEUNG")}</span>
    </h1>
  );
};

export default Loader;
