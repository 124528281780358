import React, { useState, useEffect, useRef } from "react";
import { throttle } from "throttle-debounce";
import styles from "./cursor.module.scss";

const Cursor = () => {
  const isTouchDevice = useRef(false);
  const [mouseX, setMouseX] = useState(-25);
  const [mouseY, setMouseY] = useState(-25);
  const [mouseVisible, setMouseVisible] = useState(true);

  const updateCursorPosition = throttle(50, (event) => {
    if (event.type === "touchstart") {
      isTouchDevice.current = true;
    }

    if (!isTouchDevice.current) {
      setMouseX(event.clientX);
      setMouseY(event.clientY);
    } else {
      document.body.style.cursor = "auto";
      hideCursor();
    }
  });

  const showCursor = () => {
    setMouseVisible(true);
  };

  const hideCursor = () => {
    setMouseVisible(false);
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateCursorPosition);
    window.addEventListener("touchstart", updateCursorPosition, {
      passive: false,
    });

    const documentBody = document.body;
    documentBody.addEventListener("mouseenter", showCursor);
    documentBody.addEventListener("mouseleave", hideCursor);

    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
      window.removeEventListener("touchstart", updateCursorPosition);
      documentBody.removeEventListener("mouseenter", showCursor);
      documentBody.removeEventListener("mouseleave", hideCursor);
    };
  }, []);

  return (
    <i
      className={styles.cursor}
      style={{
        top: mouseY,
        left: mouseX,
        display: isTouchDevice.current
          ? "none"
          : mouseVisible
          ? "block"
          : "none",
      }}
    />
  );
};

export default Cursor;
