import React from "react";
import { Helmet } from "react-helmet";
import Background from "../components/background/background";
import Logo from "../components/logo/logo";
import Cursor from "../components/cursor/cursor";
import "./index.module.scss";
import styles from "./index.module.scss";

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>Jason Heung | User Interface and Front-End Developer</title>
      </Helmet>
      <Cursor />
      <main>
        <Background>
          <div className={styles.comingSoon}>
            <Logo />
            <h2>new design coming soon in summer 2020.</h2>
            <a href="mailto:hello@jheung.net" target="_blank">
              get in touch
            </a>
          </div>
        </Background>
      </main>
    </>
  );
};

export default Homepage;
