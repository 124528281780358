import React, { Component } from "react";
import {
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
  DirectionalLight,
  Object3D,
  Fog,
  SphereGeometry,
  MeshPhongMaterial,
  Mesh,
  AmbientLight,
  Clock,
} from "three";
import {
  EffectComposer,
  EffectPass,
  GlitchEffect,
  RenderPass,
  NoiseEffect,
  BlendFunction,
  ChromaticAberrationEffect,
} from "postprocessing";

import { throttle } from "../../helpers/helpers";
import styles from "./background.module.css";

class Background extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.canvas = React.createRef();
    this.renderer = React.createRef();
    this.composer = React.createRef();

    this.clock = new Clock();

    this.scene = new Scene();
    this.scene.add(new AmbientLight(0x222222, 2));
    this.scene.add(new DirectionalLight(0xffffff));

    this.scene.fog = new Fog(0x000000, 1, 1000);
  }

  componentDidMount() {
    const context = this.canvas.current.getContext("webgl2", { alpha: false });
    this.renderer.current = new WebGLRenderer({
      canvas: this.canvas.current,
      context,
      antialias: true,
    });
    this.composer.current = new EffectComposer(this.renderer.current);

    this.renderer.current.setPixelRatio(window.devicePixelRatio);
    this.composer.current.setSize(
      document.body.clientWidth,
      document.body.clientHeight
    );

    this.camera = new PerspectiveCamera(
      70,
      document.body.clientWidth / document.body.clientHeight,
      1,
      1000
    );
    this.camera.position.z = 100;

    this.initCanvas();
    this.animate();
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  initCanvas = () => {
    this.setState({
      loaded: true,
    });
    this.object = new Object3D();

    for (var i = 0; i < 10; i++) {
      let geometry = new SphereGeometry(
        Math.floor(Math.random() * 2 + 1),
        Math.random() * 2 + 1,
        Math.random() * 2 + 1
      );

      let material = new MeshPhongMaterial({
        color: `hsl(210, 100%, ${
          50 + Math.round((Math.random() * 2 - 1) * 30)
        }%)`,
        wireframe: true,
      });
      let mesh = new Mesh(geometry, material);

      mesh.position
        .set(
          Math.random() * 15 - 1,
          Math.random() * 15 - 1,
          Math.random() * 15 - 1
        )
        .normalize();
      mesh.position.multiplyScalar(Math.random() * 250);
      mesh.rotation.set(
        Math.random() * 10,
        Math.random() * 10,
        Math.random() * 10
      );
      mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 150 + 15;
      this.object.add(mesh);
    }

    this.scene.add(this.object);

    // postprocessing
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.current.addPass(renderPass);

    const chromaticAberrationEffect = new ChromaticAberrationEffect();

    const glitchEffect = new GlitchEffect({
      chromaticAberrationOffset: chromaticAberrationEffect.offset,
    });

    const noiseEffect = new NoiseEffect({
      blendFunction: BlendFunction.COLOR_DODGE,
    });

    noiseEffect.blendMode.opacity.value = 0.2;

    const glitchPass = new EffectPass(this.camera, glitchEffect, noiseEffect);

    const chromaticAberrationPass = new EffectPass(
      this.camera,
      chromaticAberrationEffect
    );

    this.composer.current.addPass(glitchPass);
    this.composer.current.addPass(chromaticAberrationPass);
  };

  animate = () => {
    requestAnimationFrame(this.animate);
    var delta = this.clock.getDelta();
    this.object.rotation.x += 0.001;
    this.object.rotation.y += 0.001;
    this.composer.current.render(delta);
  };

  onWindowResize = throttle(() => {
    this.camera.aspect = document.body.clientWidth / document.body.clientHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.current.setPixelRatio(window.devicePixelRatio);
    this.composer.current.setSize(
      document.body.clientWidth,
      document.body.clientHeight
    );
  }, 50);

  render() {
    return (
      <>
        {this.props.children}
        <canvas
          ref={this.canvas}
          className={`${styles.canvas} ${
            this.state.loaded ? styles.loaded : ""
          }`}
        />
      </>
    );
  }
}

export default Background;
